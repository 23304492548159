<template>
  <section class="content">
    <div class="card card-primary">
      <div class="card-body">
        <div class="row">
          <div class="col-9">
            <div class="row form-horizontal">
              <div class="col-auto">
                <label class="control-label my-2 ml-2">
                  <h6>Filter Pencarian</h6>
                </label>
              </div>
              <div class="col-auto">
                <select class="form-control" @change="dataType($event)">
                  <option value="pickupTime">Dibuat Antara</option>
                  <option value="updatedOn">Update Antara</option>
                </select>
              </div>
              <div class="col-auto">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    ref="daterange"
                    class="form-control"
                    style="border-right: 0"
                  />
                  <div class="input-group-append">
                    <div
                      class="input-group-text"
                      style="background-color: #fff"
                    >
                      <span><span class="fa fa-calendar"></span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <button
                  class="btn btn-info dropdown-icon dropdown-toggle"
                  @click="selectStatus"
                >
                  Status
                </button>
              </div>
            </div>
          </div>
          <div class="col-3 text-right">
            <div class="btn-group" ref="toolbar">
              <button type="button" data-action="read" class="btn btn-default">
                <i class="fa fa-sync-alt"></i>
              </button>
              <button
                type="button"
                :disabled="downloading"
                v-on:click="downloadXlsx"
                data-action="export-xlsx"
                class="btn btn-default"
              >
                <i class="fa fa-download"></i> Download
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsivex">
          <table class="table table-hover" ref="tableuser" id="tblriwayat">
            <thead>
              <tr>
                <th rowspan="2">NO. ORDER</th>
                <th rowspan="2">NO. RESI</th>
                <th rowspan="2">PENGIRIM</th>
                <th rowspan="2">PENERIMA</th>
                <th rowspan="2">TELEPON</th>
                <th rowspan="2">TUJUAN</th>
                <th rowspan="2">TANGGAL</th>
                <th rowspan="2">BERAT(KG)</th>
                <th rowspan="2">NILAI BARANG</th>
                <th rowspan="2">ONGKIR</th>
                <th rowspan="2">STATUS</th>
                <th colspan="3">INFO KURIR</th>
                <th rowspan="2">ACTION</th>
              </tr>
              <tr>
                <th>FEE COD KRR</th>
                <th>DITAGIH</th>
                <th>TERIMA PAYMENT</th>
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
          </table>
        </div>
      </div>
    </div>
    <Historyfilter :show="show" :sTicket="false" :sOther="false" v-model="filter" v-on:change="updateFilter" />
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Historyfilter from "@/dialog/Historyfilter";
import moment from "moment";

export default {
  name: "Riwayat",
  data() {
    return {
      dateStart: moment().startOf("month").format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      errors: [],
      method: "",
      roles: "",
      groups: [],
      formTitle: "Tambah User",
      form: {
        name: "",
        username: "",
        email: "",
      },
      downloading: false,
      show: false,
      filter: {},
      data_type: "pickupTime",
    };
  },
  computed: {
    kurirLogo() {
      const fileName = "jne"; //logo.toLowerCase()

      return require(`../assets/img/${fileName}.png`); // the module request
    },
  },
  components: {
    Historyfilter,
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();

    authFetch("/users/groups")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.groups = js.data;
      });
  },
  methods: {
    downloadXlsx: function (e) {
      this.downloading = true;
      var filter = this.filter;
      filter.d1 = this.dateStart;
      filter.d2 = this.dateEnd;
      filter.datatype = this.data_type;

      var data = Object.keys(filter)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(filter[key])
        )
        .join("&");
      authFetch("/report/payment/excell", {
        method: "POST",
        body: data,
      })
        .then((response) => response.blob())
        .then((blob) => {
          setTimeout(() => {
            this.downloading = false;
          }, 1000);
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "data.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
    },
    updateFilter: function (e) {
      this.filter = e;
      console.log(this.filter);
      this.tbl.api().ajax.reload();
    },
    dataType(event) {
      this.data_type = event.target.value;
      this.tbl.api().ajax.reload();
    },
    onDate() {
      // var params = { dt1: this.dateStart, dt2: this.dateEnd };
      // var search = new URLSearchParams(params);
      this.tbl.api().ajax.reload();
    },
    selectStatus: function () {
      this.show = !this.show;
      console.log("click", this.show);
    },
    getKurirLogo(kurir) {
      const fileName = kurir.toLowerCase();

      return require(`../assets/img/${fileName}.png`); // the module request
    },
    handleClick(e) {
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "view") {
          this.$router.push({
            path: "/payment-kurir/detail/" + e.target.dataset.id,
          });
        }
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    const self = this;
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: moment().startOf("month"),
        endDate: moment(),
      },
      function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.data_type = self.data_type;
        self.onDate();
      }
    );
    console.log("logo", self.getKurirLogo("jne"));
    var isShipper = this.userInfo.role_id > 10;
    var isKurir = this.userInfo.role_id == 9;
    this.tbl = createTable(e.tableuser, {
      title: "Data Riwayat",
      roles: this.$route.params.roles,
      ajax: "/payment/courier/riwayat",
      frame: false,
      toolbarEl: e.toolbar,
      lengthChange: false,
      filter: false,
      "scrollX": true,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      paramData: function (d) {
        d.cod = self.filter.cod ? 1 : 0;
        d.noncod = self.filter.noncod ? 1 : 0;
        d.ts0 = self.filter.ts0 ? 1 : 0;
        d.ts1 = self.filter.ts1 ? 1 : 0;
        d.ts2 = self.filter.ts2 ? 1 : 0;
        d.ts3 = self.filter.ts3 ? 1 : 0;
        d.ts4 = self.filter.ts4 ? 1 : 0;
        d.d1 = self.dateStart;
        d.d2 = self.dateEnd;
        d.datatype = self.data_type;
      },
      columns: [
        { data: "order_code" },
        { data: "billNo" },
        {
          data: "shipperName",
          title: isShipper ? "Asal Gudang" : "Shipper",
          render: function (data, type, row, meta) {
            return row.senderName + "<br>" + row.senderCity;
          },
        },
        {
          data: "destName",
          title: "PENERIMA",
          render: function (data, type, row, meta) {
            return row.destName;
          },
        },
        { data: "destTel" },
        {
          data: "destCity",
          render: function (data, type, row, meta) {
            return row.kecamatan + ", " + row.destCity;
          },
        },
        {
          data: "lastActivity",
          render: function (data, type, row, meta) {
            return self.data_type == "updatedOn"
              ? row.updatedOn
              : row.pickupTime;
          },
        },
        {
          data: "weight",
          render: function (data, type, row, meta) {
            return row.weight;
          },
        },
        {
          data: "item_value",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(row.item_value);
          },
        },
        {
          data: "nilai_ongkir",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(row.nilai_ongkir);
          },
        },
        {
          data: "msgStatus",
          render: function (data, type, row, meta) {
            if (row.pickupStatus == 0)
              return '<span class="badge badge-inverse">DRAFT</span>';
            else {
              if (row.trackingStatus == 0)
                if(row.order_type == 1){
                  return '<span class="badge badge-secondary">Menunggu Drop</span>';
                } else {
                  return '<span class="badge badge-secondary">Menunggu Pickup</span>';
                }
              else if (row.trackingStatus == 1)
                return '<span class="badge badge-warning">Proses</span>';
              else if (row.trackingStatus == 2)
                return '<span class="badge badge-success">Terkirim</span>';
              else if (row.trackingStatus == 3)
                return '<span class="badge badge-danger">Proses Retur</span>';
              else return '<span class="badge badge-info">Retur Selesai</span>';
            }
          },
        },
        {
          data: "disc",
          render: function (data, type, row, meta) {
            return "-";
          },
        },
        {
          data: "disc",
          render: function (data, type, row, meta) {
            return "-";
          },
        },
        {
          data: "disc",
          render: function (data, type, row, meta) {
            return "-";
          },
        },
        {
          data: "order_no",
          render: function (data, type, row, meta) {
            return (
              `<div class="btn-group">
          <button type="button" class="btn btn-sm btn-outline-info" data-action="view" data-id="` +
              row.order_no +
              `"><i class="fas fa-eye"></i> Lihat detail</button>
              </div>`
            );
          },
        },
      ],
      filterBy: [1, 2],
      rowCallback: function (row, data) {
        // $("td:eq(0)", row).html(
        //   '<a class="link link-order text-info" style="cursor:pointer;" data-id="' +
        //     data.order_code +
        //     '">' +
        //     data.order_code +
        //     "</a>"
        // );
      },
    });
  },
};
</script>